
import { defineComponent, onMounted } from "vue";
import KTModalCard from "@/components/cards/Card.vue";
import KTNewAddressModal from "@/components/modals/forms/NewAddressModal.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { getIllustrationsPath } from "@/core/helpers/assets";

export default defineComponent({
  name: "new-address",
  components: {
    KTModalCard,
    KTNewAddressModal,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("New Address", ["Modals", "Forms"]);
    });

    return {
      getIllustrationsPath,
    };
  },
});
